<template>
  <div
    v-editable="blok"
    class="MarqueeVisualBannerBlockt"
    :class="{
      [desktopWidth('full')]: !blok.fullWidth,
      'w-screen': blok.fullWidth,
    }"
  >
    <client-only>
      <Vue3Marquee
        :clone="true"
        :duration="Number(duration)"
        :pause-on-hover="true"
        class="w-full type-headline-xl desk:type-headline-4xl select-none"
      >
        <div
          v-for="item in props.blok.marqueeItemList"
          :key="item._uid"
          class="whitespace-nowrap transition mr-16 desk:mr-24"
        >
          <div
            class="flex flex-row gap-16 desk:gap-24"
          >
            <div>
              <div
                v-if="item.image && item.image.filename"
                class="relative w-48 h-48 desk:w-80 desk:h-80"
              >
                <nuxt-img
                  preset="standard"
                  loading="lazy"
                  sizes="sm:48 desk:80"
                  class="absolute w-full h-full object-cover"
                  :src="item.image.filename"
                  :alt="item.image.alt"
                />
              </div>
              <div v-else class="pt-12 desk:pt-16">•</div>
            </div>
            <div>
              <div class="pt-12 desk:pt-16">
                <nuxt-link
                  v-if="$sbLinkCheck(item.link)"
                  :to="$sbLinkFix(item.link)"
                  :target="$sbLinkTarget(item.link)"
                  class="border-b border-darkest/20 hover:border-darkest transition-all"
                >
                  {{ item.text }}
                </nuxt-link>
                <div v-else> {{ item.text }} </div>
              </div>
            </div>
          </div>
        </div>
      </Vue3Marquee>
    </client-only>
  </div>
</template>

<script lang="ts" setup>

import {
  SiteMarqueeItem,
  StoryBlokPropBase
} from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { Vue3Marquee } from 'vue3-marquee';

type Props = StoryBlokPropBase & {
  marqueeItemList: SiteMarqueeItem[];
  fullWidth: boolean;
  stationary: boolean;
  marqueeSpeed: number,
}

const props = defineProps<{
  blok: Props,
}>();

const duration = ref(props.blok.marqueeSpeed || 10); // Default to 10 if not present

const { desktopWidth } = useDesktopWidth(props);

</script>

<style scoped lang="postcss">

</style>
